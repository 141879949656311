<template>
    <div class="pathology-list">
        <PathologyItem v-for="(item, idx) in pathologyList" :key="idx" :pathology="item"></PathologyItem>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import PathologyItem from './../../components/patient/PathologyItem'

export default {
    name:'PathologyList',
    components : {
        PathologyItem
    },
    data() {
        return {
            pathologyList : []
        }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"monitoring/" + this.$route.params.id, config)
            .then(function (response) {
                vm.pathologyList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>


    .pathology-list {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height:calc(100vh - 271px);
    }
</style>