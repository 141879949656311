<template>
    <div class="pathology-item">
        <div class="row">
            <div class="col25">
                <img :src="`${this.$store.state.URL_ASSESTS}/${pathology.image}`" alt="pathology" />
            </div>
            <div class="col75">
                <h2>{{ JSON.parse(pathology.name)[this.$store.state.LANG] }}</h2>
                <h3>{{ pathology.center }}</h3>
                <p v-html="JSON.parse(pathology.desc)[this.$store.state.LANG]"></p>
                <div class="timeline-container">
                    <PathologyStep v-for="(item, idx) in pathology.periods" :key="idx" :step="item"></PathologyStep>
                </div>
                <template v-if="$store.state.USER.role === 4">
                    <div v-if="pathology.acceptance" @click="goToPathology" class="btn pathology-btn">{{ $t('patient.btnText')}}</div>
                    <div v-else @click="validatePathology" class="btn pathology-btn">{{ $t('patient.btnText')}}</div>
                </template>
                <template v-else>
                    <div @click="goToPathology" class="btn pathology-btn">{{ $t('patient.btnText')}}</div>
                </template>
                <div class="injection-link" @click="goInjection" v-if="pathology.current_period != 1 && pathology.current_period != 2">{{ $t('patient.displayInjection')}}</div>
            </div>
        </div>
        
        <p class="date-next" v-if="dateIntervention && dateIntervention !=1 && pathology.is_processing === 2 ">{{ $t('patient.dateNext')}} : {{ dateIntervention }}</p>
        <p class="date-next" v-if="dateIntervention && dateIntervention ===1 ">{{ $t('patient.dateProcessing')}}</p>
        
    </div>
</template>

<script>
import PathologyStep from './PathologyStep'

export default {
    name : "PathologyItem",
    props : ['pathology'],
    components : {
        PathologyStep
    },
    methods : {
        goToPathology() {
            if(this.$store.state.USER.infos.role_id === 2) {
                this.$router.push({ name : 'MonitoringCoordinator', params : { id : this.pathology.pathology_monitoring_id}})
            }
            if(this.$store.state.USER.infos.role_id === 3) {
                this.$router.push({ name : 'MonitoringDoctor', params : { id : this.pathology.pathology_monitoring_id}})
            }
            if(this.$store.state.USER.infos.role_id === 4) {
                this.$router.push({ name : 'Pathology', params : { id : this.pathology.pathology_monitoring_id}})
            }
        },
        validatePathology() {
            this.$router.push('activation-pathologie/' + this.pathology.pathology_monitoring_id)
        },
        goInjection() {
            if(this.$store.state.USER.role === 4) {
                this.$router.push({ name : 'Pathology', params : { id : this.pathology.pathology_monitoring_id, step : 2}})
            }else if(this.$store.state.USER.role === 3) {
                this.$router.push({ name : 'MonitoringDoctor', params : { id : this.pathology.pathology_monitoring_id, step : 2}})
            }else if(this.$store.state.USER.role === 2) {
                this.$router.push({ name : 'MonitoringCoordinator', params : { id : this.pathology.pathology_monitoring_id, step : 2}})
            }
        }
    },
    computed : {
        dateIntervention() {
            if(this.pathology ) {
                let currentDate = this.pathology.periods.find(e => e.id === this.pathology.current_period)
                if(currentDate) {
                    if(!currentDate.todo) {
                        return new Date(currentDate.date).toLocaleDateString('fr-FR')
                    }else {
                        if(this.pathology.current_period === 2)  {
                            return null
                        }else {
                            return 1
                        }
                    }
                } else {
                    return null
                }
            }else {
                return null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .pathology-item {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:40px auto;
        padding:20px;
        width:$boxWidth;
        max-width: calc(90% - 40px);
        position:relative;
    }

    .pathology-item .row {
        align-items: center;
    }

    .pathology-item .col25 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .pathology-item .col25 img {
        max-width: 90%;
        height:auto;
    }

    .pathology-item h2 {
        font-size:22px;
        color:$mainColor;
        margin: 0;
        font-family: $fontBlack;
    }

    .pathology-item h3 {
        font-size:22px;
        color:$mainColor;
        margin: 0 0 10px 0;
        font-family: $font;
    }

    .pathology-item p {
        color:$textColor;
        font-size:15px;
        text-align: justify;
    }

    .pathology-btn {       
        width:200px;      
        margin:0 auto; 
    }

    p.date-next {
        text-align: center;
        width: 90%;
        max-width: 90%;
        margin: auto;
        color:$textColor;
        font-size:15px;
        font-family: $fontMedium;
        position: absolute;
        bottom:-30px;
    }

    .timeline-container {
        padding-bottom:20px;
    }

    .injection-link {
        cursor: pointer;
        text-align: center;
        padding:0 10px;
        margin-top:10px;
    }

    .injection-link:hover {
        text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
        .pathology-item .row {
            flex-direction: column;
        }

        .col25, .col75 {
            width:100%;
        }

        .col25 img  {
            margin: 0 auto 10px auto;
        }

        h2 {
            text-align: center;
        }

        h3 {
            text-align: center;
        }

        p.date-next {
            bottom:-40px;
        }

        .pathology-item p {
            font-size:12px;
        }
    }
    
</style>
